import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Head from "../components/head"

const NotFound = () => {
  return (
    <Layout>
      <Head title="404" />
      <h1>Page Not Found</h1>
      <p>
        The requested URL was not found on the server. If you entered the URL
        manually please check your spelling and try again.
        <Link to="/">Head home</Link>.
      </p>
    </Layout>
  )
}

export default NotFound
